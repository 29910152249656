// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-medverkande-foretag-tsx": () => import("./../../../src/pages/medverkande-foretag.tsx" /* webpackChunkName: "component---src-pages-medverkande-foretag-tsx" */),
  "component---src-pages-om-mottagningen-tsx": () => import("./../../../src/pages/om-mottagningen.tsx" /* webpackChunkName: "component---src-pages-om-mottagningen-tsx" */),
  "component---src-pages-osa-tsx": () => import("./../../../src/pages/osa.tsx" /* webpackChunkName: "component---src-pages-osa-tsx" */),
  "component---src-pages-schema-tsx": () => import("./../../../src/pages/schema.tsx" /* webpackChunkName: "component---src-pages-schema-tsx" */),
  "component---src-pages-studienamnden-tsx": () => import("./../../../src/pages/studienamnden.tsx" /* webpackChunkName: "component---src-pages-studienamnden-tsx" */),
  "component---src-pages-trygghetsfunktioner-tsx": () => import("./../../../src/pages/trygghetsfunktioner.tsx" /* webpackChunkName: "component---src-pages-trygghetsfunktioner-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

